<template>
    <div class="form-horizontal">
        <p>{{ (fido2Invite ? 'Register' : 'Login')}} with {{ title }} requires that your user account has been set up. If you are not able to login, please contact your system administrator.</p>
        <div class="mb-2 mt-2" v-if="fido2Invite">
            <label clas="form-label" for="username">Email, Username or Mobile No</label>
            <input v-on:keyup.enter="authenticate" :disabled="isAuthenticated == true" type="text" name="username" v-model.trim="trimmedUsername" id="username" class="form-control" :class="{ 'is-invalid' : errors.username }" autocomplete="username" required />
            <span class="invalid-feedback mt-0" v-if="errors.username">{{ errors.username }}</span>
        </div>
        <div class="alert alert-danger mt-2" v-if="errors.error">{{ errors.error }}</div>
        <button type="button" :disabled="isAuthenticated && !fido2Invite" :name="name" @click="fido2Invite ? register() : authenticate()" class="login-alternative btn btn-o365-login" :title="`${fido2Invite ? 'Register' : 'Sign'} in with ${title}`">{{ (isAuthenticated && !fido2Invite ? 'Logged in' : (fido2Invite ? 'Register' : 'Log in')) }} with {{ title }}</button>
        <button type="button" v-if="fido2Invite && isModal" @click="cancel" class="login-alternative btn btn-secondary float-end">Close</button>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed, onMounted, inject } from 'vue';
    import { createRequest, getQueryParam, getTitle, coerceToBase64Url, coerceToArrayBuffer, loadReCaptcha, MultiFactorStates } from './shared.js';

    const authenticated = inject('authenticated') as Function;
    const isBusy = inject('isBusy') as Function;
    const close = inject('close') as Function;
    const isRecaptchaConfigured = computed(() => props.state.siteKey && props.state.siteKey.length > 0);
    const props = defineProps({
        name: String,
        title: String,
        state: {
            type: Object,
            required: true
        }
    });
    
    var isModal = location.pathname != '/login';
    var token = getQueryParam('token');
    var fido2 = getQueryParam('fido2key');
    const queryUsername = getQueryParam('Username');
    const title = computed(() => {
        return props.title ?? getTitle('fido2');
    });

    const fido2Invite = computed(() => token?.length > 0 && fido2?.length > 0 || props.state.action === 'fido2' && props.state.multiFactorState == MultiFactorStates.Register);
    const errors = ref<{
        error?: string,
        success?: string,
        username?: string
    }>({});
    const username = ref('');
    const trimmedUsername = computed({
        get() {
            return username.value;
        },
        set(value) {
            username.value = value?.replace(/\s/g, '');
            validate();
        }
    });

    const isAuthenticated = computed(() => props.state.isAuthenticated);
    const isMfa:Boolean = location.pathname.endsWith('/login/mfa');

    function validate(){
        errors.value = {};
        if(fido2Invite.value && !username.value.length){
            errors.value['username'] = 'Username field is required';
            return false;
        }
        if(props.state.isAuthenticated && !isMfa) return false;
        return true;
    }

    async function authenticate() {
        if(isAuthenticated.value && !isMfa)  return;
        if(!validate()) {
            return;
        }
        isBusy(true);
        try {

            var optionsResponce = await createRequest('/api/fido2/assertOptions', { username: username.value});
            var options = await optionsResponce.json();
            if(!optionsResponce.ok){
                errors.value = options.errors;
                return;
            }
            const challenge = options.challenge.replace(/-/g, "+").replace(/_/g, "/");
            options.challenge = Uint8Array.from(atob(challenge), c => c.charCodeAt(0));
            
            options.allowCredentials.forEach(function (listItem) {
                var fixedId = listItem.id.replace(/\_/g, "/").replace(/\-/g, "+");
                listItem.id = Uint8Array.from(atob(fixedId), c => c.charCodeAt(0));
            });            

            let credential = await navigator.credentials.get({ publicKey: options });

            await verifyAssertion(credential);
            authenticated();

        } catch (err) {
            errors.value['error'] = err.message;
        } finally {
            isBusy(false);
        }
    }

    async function getCredentialOptionsAsync(){
        try {
            var res = await createRequest('/api/fido2/registerOptions', { username: username.value, token: token, fido2key: fido2 });
            return await res.json();
        } catch (e) {
            errors.value['error'] = ("Request to server failed: " + e.message);
        }
    }

    async function verifyRegister(assertedCredential) {
        let attestationObject = new Uint8Array(assertedCredential.response.attestationObject);
        let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
        let rawId = new Uint8Array(assertedCredential.rawId);
        const attestation = {
            id: assertedCredential.id,
            rawId: coerceToBase64Url(rawId),
            type: assertedCredential.type,
            extensions: assertedCredential.getClientExtensionResults(),
            response: {
                AttestationObject: coerceToBase64Url(attestationObject),
                clientDataJSON: coerceToBase64Url(clientDataJSON)
            }
        };

        const data = {
            attestation: attestation,
            token: token
        };

        if(isRecaptchaConfigured.value) {
            data['reToken'] = await grecaptcha.execute(props.state.siteKey, {action: 'submit'});
        }
        let response;
        try {
            let res = await createRequest("/api/fido2/register", data);
            response = await res.json();
        } catch (e) {
            errors.value['error'] = e.message;
            return;
        }

        // show error
        if (response.status !== "ok") {
            errors.value['error'] = response.errorMessage;
            return;
        }
        
        token = undefined;
        fido2 = undefined;
        if(fido2Invite.value && isModal){
            authenticated();
        } else errors.value['success'] = 'Successfully registered';
    }

    async function register(){
        try {
            isBusy(true);
            let makeCredentialOptions = await getCredentialOptionsAsync();
            if (makeCredentialOptions.status !== "ok") {
                errors.value['error'] = makeCredentialOptions.errorMessage;
                return;
            }
            makeCredentialOptions.challenge = coerceToArrayBuffer(makeCredentialOptions.challenge);
            makeCredentialOptions.user.id = coerceToArrayBuffer(makeCredentialOptions.user.id);
            makeCredentialOptions.excludeCredentials = makeCredentialOptions.excludeCredentials.map((c) => {
                c.id = coerceToArrayBuffer(c.id);
                return c;
            });

            if (makeCredentialOptions.authenticatorSelection.authenticatorAttachment === null) makeCredentialOptions.authenticatorSelection.authenticatorAttachment = undefined;

            let newCredential = await navigator.credentials.create({
                publicKey: makeCredentialOptions
            });

            await verifyRegister(newCredential);

        } catch (e) {
            console.error(e);
            errors.value['error'] = e.message;
        } finally {
            isBusy(false);
        }
    }

    async function verifyAssertion(assertedCredential) {
        // Move data into Arrays incase it is super long
        let authData = new Uint8Array(assertedCredential.response.authenticatorData);
        let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
        let rawId = new Uint8Array(assertedCredential.rawId);
        let sig = new Uint8Array(assertedCredential.response.signature);
        const data = {
            id: assertedCredential.id,
            rawId: coerceToBase64Url(rawId),
            type: assertedCredential.type,
            extensions: assertedCredential.getClientExtensionResults(),
            response: {
                authenticatorData: coerceToBase64Url(authData),
                clientDataJson: coerceToBase64Url(clientDataJSON),
                signature: coerceToBase64Url(sig)
            }
        };
        
        let res = await createRequest("/api/fido2/makeAssertion", data);
        let response = await res.json();

        // show errorma
        if (response.status !== "ok") {
            throw new Error(response.errorMessage);
        }
        props.state.multiFactorState = MultiFactorStates.Login;
        errors.value['success'] = 'You\'re logged in successfully.';
    }

    function cancel(){
        close();
    }

    onMounted(() => {
        if(fido2Invite.value){
            loadReCaptcha(props.state.siteKey);
            username.value = queryUsername;
        } 
        if(props.state.isAuthenticated){
            username.value = props.state.username;
        } 
        if(props.state.hasError){
            errors.value['error'] = props.state.error;
        }
        if(isMfa){
            console.log('authenticating mfa fido2');
            authenticate();
        }
        isBusy(false);
    });

</script>